$(function () {
  $('#autopay_btn').on('click', function () {
    autoPayAjax()
  })

  $('#delcard').on('click', function () {
    showDialog('#delcardpopup', 'Отвязка карты', 'Отмена', 'Отвязать', delСardAjax)
  })

  $('#addcard').on('click', function () {
    showDialog('#addcardpopup', 'Привязка карты', 'Отмена', 'Привязать', addСardAjax)
  })

  function showDialog (id, title, notConfirmText, confirmText, ajaxFunction) {
    $(function () {
      $(id).dialog({
        autoOpen: true,
        modal: true,
        width: 736,
        title: title,
        buttons: [
          {
            text: notConfirmText,
            click: function () {
              $(this).dialog('close')
            }
          },
          {
            text: confirmText,
            click: function () {
              ajaxFunction()
              $(this).dialog('close')
            }
          }
        ]
      })
    })
  }

  function autoPayAjax () {
    sendAjax('/engine/ajax/dlesubscribing-autopay.php', { userid: $('#userid').val() }, function () {
      location.reload()
    })
  }

  function delСardAjax () {
    sendAjax('/engine/ajax/dlesubscribing-delcard.php', { userid: $('#userid').val() }, function () {
      location.reload()
    })
  }

  function addСardAjax () {
    sendAjax('/engine/ajax/dlesubscribing-addcard.php', { userid: $('#userid').val() }, function (payment_url) {
      window.location.href = payment_url
    })
  }

  function sendAjax (url, data, successCallback) {
    $.ajax({
      url: url,
      type: 'post',
      data: data,
      error: function () {
          DLEalert('Для оформления подписки напиши нам в Чат Telegram, или на почту support@inoriginal.ru', 'Ошибка')
      },
      success: successCallback
    })
  }

  $('#promocode_link, #promocode_header').on('click', function () {
    document.body.classList.add('hide')
    $('.promocode-popup-block').addClass('show-block')
    $('html,body').animate({ scrollTop: 0 }, 400)

    setTimeout(function () {
      $('.promocode-popup-block').addClass('show-block_effect')
    }, 200)
  })

  $(document).keydown(function (event) {
    if (event.which == 27) {
      closePromocodePopup()
    }
  })

  function closePromocodePopup () {
    document.body.classList.remove('hide')
    $('.promocode-popup-block').removeClass('show-block')

    setTimeout(function () {
      $('.promocode-popup-block').removeClass('show-block_effect')
    }, 200)
  }

  $('.promocode-popup-close-link').on('click', function () {
    setTimeout(function () {
      $('.promocode-popup-block').removeClass('show-block')
      document.body.classList.remove('hide')
    }, 400)
  })

  $('#promocode_form_btn').on('click', function () {
    $.ajax({

      url: '/engine/ajax/dlesubscribing-promocode.php',
      type: 'post',

      data: {

        promocode: $('#promocode').val(),
        userid: $('#userid').val()

      },
      beforeSend: function () {
        $('.promocode-form-wait').show()
      },
      success: function (data) {
        $('.promocode-form-wait').hide()
        $('#promocode_result').html(data)

        setTimeout(function () {
          $('#promocode_result').html('')
        }, 100000)
      },
      error: function () {
        $('.promocode-form-wait').hide()
      }

    })
  })

  $('#auth_link, #auth_mob_link, #fav_auth_link, #comm_auth_link').on('click', function () {
    closeAboutsubPopup()
    closePromocodePopup()
  })

  $('#aboutsub_link, #aboutsub_link_tariffs, #aboutsub_link_login, #aboutsub_link_footer').on('click', function () {
    document.body.classList.add('hide')
    $('.aboutsub-popup-block').addClass('show-block')
    $('html,body').animate({ scrollTop: 0 }, 400)

    setTimeout(function () {
      $('.aboutsub-popup-block').addClass('show-block_effect')
    }, 200)
  })

  $(document).keydown(function (event) {
    if (event.which == 27) {
      closeAboutsubPopup()
    }
  })

  function closeAboutsubPopup () {
    document.body.classList.remove('hide')
    $('.aboutsub-popup-block').removeClass('show-block')

    setTimeout(function () {
      $('.aboutsub-popup-block').removeClass('show-block_effect')
    }, 200)
  }

  $('.aboutsub-popup-close-link').on('click', function () {
    setTimeout(function () {
      $('.aboutsub-popup-block').removeClass('show-block')
      document.body.classList.remove('hide')
    }, 400)
  })
})
function redirectToPay (button) {
  const rateName = button.getAttribute('data-rate')
  const userId = button.getAttribute('data-userid')
  $.ajax({
    url: '/engine/ajax/dlesubscribing-pay.php',
    type: 'post',
    data: {
      userid: userId,
      rate_name: rateName
    },
    error: function () {
      DLEalert('Для оформления подписки напиши нам в <a href="https://t.me/inoriginal_support" target="_blank">Чат Telegram</a>, или на почту <a href="mailto:support@inoriginal.ru?subject=Обращение%20в%20службу%20поддержки" target="_blank">support@inoriginal.ru</a>', 'Ошибка')
    },
    success: function (payment_url) {
      window.location.href = payment_url
    }
  })
}
